import React from "react"
import HelmetTitle from "../components/utils"
import { Container, Row, Col } from "reactstrap"
import "../scss/modules/_m-hero-process.scss"
import icon1 from "../images/meeting_icon.png"
import icon2 from "../images/Storyboarding_icon.png"
import icon3 from "../images/ui_icon.png"
import icon4 from "../images/Sprints_icon.png"
import icon5 from "../images/Feedback_icon.png"
import icon6 from "../images/Acceptance_icon.png"
import Layout from "../components/Layout"

const ProcessPage = () => {
  const tagline = "Our Process",
    title = `Well-structured processes describe how things could be done and allow us to
      focus on making always better outcomes. We believe that having processes in place and 
      sharing them with clients in an early stage is the prerequisite to the success of any
      business organization.`

  return (
    <Layout>
      <div className="main">
        <HelmetTitle title="Processes" />
        <div className="process-banner pt-6 pb-6 px-4 text-white w-100">
          <Row>
            <Col xl={{ size: 3, offset: 1 }}>
              <h1 className="mb-5 font-weight-bold">{tagline} </h1>
            </Col>
          </Row>
          <Row>
            <Col xl={{ size: 10, offset: 1 }} lg={12}>
              <h4 className="mb-5 text-justify">{title}</h4>
            </Col>
          </Row>
        </div>
        <Container className="steps" fluid>
          <Row noGutters>
            <Col xl={{ size: 10, offset: 1 }} lg={12}>
              <Row className="mt-5 mb-4" noGutters>
                <img src={icon1} alt="Icon of meeting" />
                <div className="ml-3 mt-3">
                  <h5 id="step-1" className="step-num font-weight-bold">
                    Step 1: Let's Meet
                  </h5>
                  <p className="step-title font-weight-bold"> Customer</p>
                </div>
              </Row>
              <Row noGutters>
                <p>
                  Let us catch up over a coffee, phone call, or web conference
                  and discuss your vision and the problem you want to solve. Go
                  ahead and submit a brief explanation of your project and we
                  will get back to you within 24 hours.
                </p>
              </Row>
              <div className="line2" />
              <div className="line mb-4 mt-1" />
              <Row className="mb-4" noGutters>
                <img src={icon2} alt="Icon of Storyboarding" />
                <div className="ml-3 mt-3">
                  <h5 id="step-2" className="step-num font-weight-bold">
                    Step 2: Storyboarding
                  </h5>
                  <p className="step-title font-weight-bold">
                    Foretheta + Customer
                  </p>
                </div>
              </Row>
              <Row noGutters>
                <p>
                  After our meeting, we will write user stories that clearly
                  define the scope of the project. We put your requirements down
                  into a document. This clearly defines the scope of projects
                  and ensure both parties know what we are getting into.
                </p>
                <ul>
                  <li>Subject matter and data can be openly discussed</li>
                  <li>Minimum viable product is achievable in 4 to 6 weeks</li>
                  <li>Technical fit for the skillset of the Foretheta team</li>
                </ul>
              </Row>
              <div className="line2" />
              <div className="line mb-4 mt-1" />
              <Row className="mb-4" noGutters>
                <img src={icon3} alt="Icon of UI" />
                <div className="ml-3 mt-3">
                  <h5 id="step-3" className="step-num font-weight-bold">
                    Step 3: Prototyping and UX
                  </h5>
                  <p className="step-title font-weight-bold">
                    Foretheta + Customer
                  </p>
                </div>
              </Row>
              <Row noGutters>
                <p>
                  Our design team creates the wireframes for the user stories
                  and presents them to you. We get your feedback and incorporate
                  them and come up with the designs we will be building on. For
                  backend projects with no or little UI, we put together request
                  and response interfaces for the APIs being built.
                </p>
              </Row>
              <div className="line2" />
              <div className="line mb-4 mt-1" />
              <Row className="mb-4" noGutters>
                <img src={icon4} alt="Icon of Sprints" />
                <div className="ml-3 mt-3">
                  <h5 id="step-4" className="step-num font-weight-bold">
                    Step 4: Sprints
                  </h5>
                  <p className="step-title font-weight-bold">Foretheta</p>
                </div>
              </Row>
              <Row noGutters>
                <p>
                  We break our product development cycle into many sprints that
                  are about 3 weeks each. Each sprint has a monday morning
                  sprint planning meeting and daily ten minute stand ups. At the
                  second friday we have a sprint conclusion demo and a
                  retrospective.
                </p>
              </Row>
              <div className="line2" />
              <div className="line mb-4 mt-1" />
              <Row className="mb-4" noGutters>
                <img src={icon5} alt="Icon of Feedback" />
                <div className="ml-3 mt-3">
                  <h5 id="step-5" className="step-num font-weight-bold">
                    Step 5: Feedback
                  </h5>
                  <p className="step-title font-weight-bold">
                    Foretheta + Customer
                  </p>
                </div>
              </Row>
              <Row noGutters>
                <p>
                  We set up a staging server where our continuous delivery
                  manager will automatically deploy the latest code we push. You
                  can check the status of your project anytime on our staging
                  server and provide us feedback.
                </p>
              </Row>
              <div className="line2" />
              <div className="line mb-4 mt-1" />
              <Row className="mb-4" noGutters>
                <img src={icon6} alt="Icon of Acceptance" />
                <div className="ml-3 mt-3">
                  <h5 id="step-6" className="step-num font-weight-bold">
                    Step 6: Acceptance and Completion
                  </h5>
                  <p className="step-title font-weight-bold"> Customer</p>
                </div>
              </Row>
              <Row className="mb-2" noGutters>
                <p>
                  Once we deliver a user story, you can test it out on staging
                  server and accept the story or give us feedback if any. Once
                  all the stories have been accepted by you, we prepare the
                  project for delivery and give you a documentation of our work.
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ProcessPage
